<template>
  <atoms-base-button class="btn_default" v-bind="$attrs">
    <slot />
  </atoms-base-button>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
.btn_default {
  padding: 14px 16px;
  background-color: $blue1;
  border-radius: 8px;
  @include transition();
  &:not(:disabled):hover {
    background-color: $blue2;
  }
  &:disabled {
    background-color: $btn-disable;
  }
  &:deep(.txt_btn) {
    color: $white;
    font-size: 18px;
    font-weight: 700;
    line-height: 150%; /* 27px */
  }
}
</style>
